.r-best-vehicles {
  background-color: #f8f4da;
  padding: 0 0 130px;
  //.r-best-vehicle-list-outer{margin-top: 40px;}
  .r-best-vehicle-list {
    .r-best-vehicle-single {
      background-color: #fff;
      margin-bottom: 30px;

      &:hover {
        box-shadow: 0 0 40px #ccc;
        -webkit-box-shadow: 0 0 40px #ccc;
        @extend .r-transition-fast;
      }

      .r-best-vehicle-content {
        padding: 25px 25px 0 25px;
        // .r-register-time{font-size: 13px; display: block; margin-bottom: 8px; color: #333333;}
        .r-modal-name {
          font-size: 20px;
          color: #333333;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 15px;

          &:hover {
            text-decoration: none;
            color: $accent_color;
          }

          span {
            color: $accent_color;
            font-weight: 400;
          }
        }

        .r-engine {
          float: right;
          font-size: 14px;
          color: #bbbbbb;
          margin-top: 10px;

          img {
            margin-right: 10px;
          }
        }

        .r-average {
          background-color: $accent_color;
          padding: 7px 16px;
          display: inline-block;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
        }

        > ul {
          margin-bottom: 0;
          border-top: 1px solid #eeeeee;
          padding: 15px 25px;
          margin-top: 15px;
          margin-left: -25px;
          margin-right: -25px;
          @extend .clearfix;

          li {
            float: left;
            list-style: none;
            margin-right: 12px;

            i {
              font-size: 13px;
              color: #aaaaaa;
              display: inline-block;
              margin-right: 5px;
            }

            span {
              font-size: 12px;
              color: #aaaaaa;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .r-best-vehicle-types {
    list-style: none;
  }

  .r-best-leftbar {
    .r-best-vehicle-types {
      max-width: 250px;
      background-color: $accent_color;

      li {
        position: relative;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        transition: all 0.2s linear;

        span {
          position: relative;
          display: block;
          margin: 0 20px;
          cursor: pointer;
          padding: 10px 0;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border-bottom: 1px solid $accent_color;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -ms-transition: all 0.2s linear;
          transition: all 0.2s linear;

          &::before {
            content: "";
            display: inline-block;
            height: 20px;
            margin-right: 0;
            width: 0;
            background-color: $accent_color;
            position: relative;
            top: 5px;
          }
        }

        &::after {
          position: absolute;
          opacity: 0;
          right: -10px;
          top: 0;
          height: 100%;
          width: 10px;
          content: '';
          background-color: $accent_color;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -ms-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }

        &.r-best-vehicle-acitve {
          background-color: #fff;

          &::after {
            opacity: 1;
          }

          span {
            background-color: #fff;
            color: #333;
            border: none;

            &::before {
              margin-right: 15px;
              width: 3px;
            }
          }
        }

        &:hover {
          background-color: #fff;

          span {
            color: #333;
            border-color: #fff;
          }
        }
      }
    }
  }
}

.r-best-vehicles-trans {
  background: none;

  .r-best-offer-list .r-best-offer-single {
    background-color: #fafafa;
  }

  .r-best-offer-list .r-best-offer-single .r-best-offer-content {
    background-color: #fafafa;
  }
}

.owl-theme {
  text-align: center;
  -webkit-tap-highlight-color: transparent;

  .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;

    &.active {
      span {
        background: #869791;
      }
    }

    span {
      width: 9px;
      height: 9px;
      margin: 2.7px 2.5px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity .2s ease;
      border-radius: 30px;
    }
  }
}

.r-best-offer-list {
  .r-best-offer-single {
    background-color: #fff;
    text-align: center;
    position: relative;
    margin-bottom: 62px;

    .r-offer-img {
      padding-top: 30px;
      position: relative;

      .r-offer-img-over {
        @extend .r-transition-fast;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .85);
        @extend .r-transition-fast;
        opacity: 0;

        i {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 35px;
          height: 35px;
          background-color: $accent_color;
          border-radius: 50%;
          color: #fff;
          line-height: 35px;
          font-size: 18px;

          &:hover {
            background-color: #333;
          }
        }
      }
    }

    .r-best-offer-content {
      text-align: center;
      padding-bottom: 25px;
      position: relative;
      z-index: 999;
      background: #fff;

      > a {
        font-size: 28px;
        color: $accent_color;
        font-weight: 400;
        display: inline-block;
        margin-bottom: 0;

        &:hover {
          text-decoration: none;
          color: $accent_color;

          b {
            color: $accent_color;
          }
        }

        b {
          color: #333;
          font-weight: 700;
        }
      }

      > p {
        margin-bottom: 30px;
      }
    }

    ul {
      padding-left: 0;
      list-style: none;
      @extend .clearfix;
      text-align: center;

      li {
        display: inline-block;
        color: #aaaaaa;
        background-color: #f4f4f4;
        padding: 2px 12px;

        &:hover {
          background-color: $accent_color;
          color: #fff;
        }

        i {
          font-size: 12px;
          display: inline-block;
          margin-right: 5px;
        }

        span {
          font-size: 11px;
        }
      }
    }

    .r-offer-rewst-this {
      @extend .btn-full;
      top: calc(100% - 70px);
      left: 0;
      position: absolute;
      @extend .r-transition-fast;
    }

    &:hover {
      .r-offer-rewst-this {
        top: 100%;
      }

      .r-offer-img {
        .r-offer-img-over {
          opacity: 1;
        }
      }
    }
  }
}


@media (max-width: 1400px) {
  .r-best-vehicles {
    padding-bottom: 80px;
  }
  .r-best-offer-list .r-best-offer-single .r-best-offer-content > a {
    font-size: 24px;
  }
  .r-best-offer-list .r-best-offer-single .r-best-offer-content > p {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-modal-name {
    font-size: 18px;
  }
  .r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-average {
    font-size: 14px;
  }
  .r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content > ul {
    padding: 10px 25px;
  }
  .r-best-vehicles .r-best-leftbar .r-best-vehicle-types li span {
    padding: 8px 0px;
    font-size: 13px;
  }
  .r-best-vehicles .r-best-leftbar .r-best-vehicle-types li::after {
    width: 5px;
    right: -5px;
  }
  .r-best-vehicles {
    padding: 0 0 80px;
  }
}

@media (max-width: 1200px) {
  .r-best-offer-list {
    .r-best-offer-single ul li {
      margin-bottom: 5px;
    }
  }
  .r-best-vehicles {
    padding: 0 0 30px;
  }
  .r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-engine {
    float: none;
    display: block;
  }
}

@media (max-width: 767px) {
  .r-best-offer-list .r-best-offer-single .r-offer-rewst-this {
    top: 100%;
  }
  .r-best-vehicles .r-best-vehicle-list-outer {
    margin-top: 10px;
  }
  .r-best-vehicles .r-best-vehicle-list .r-best-vehicle-single .r-best-vehicle-content .r-engine {
    float: right;
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .r-best-vehicles .r-best-leftbar .r-best-vehicle-types {
    margin: 0 auto 20px !important;
  }
}
