.r-contact-part{ padding:60px 0;
	.contact-head{ position: relative;
		span { font-size:13px; color:#333;letter-spacing:3px;}
		h2 {font-size:40px; font-weight:300; line-height:48px;
			b{  font-weight:500; }
		}
		&:after{ content: "C";  font-size: 200px; color: #f8f8f8;font-weight: 700;
    			position: absolute; left:-80px; right: 0; top: -80px; z-index: -1;}
	}
	.r-contact-address{
		margin-top:65px;
		.head{ font-size:25px; color:#333; margin-bottom: 35px;}
		.r-contact-img{ margin-bottom: 20px; display: inline-block;}
		.address-cnt{font-size:20px; font-weight:300; margin-top: 20px;
			b{ font-weight:700;}
			i{ width:60px; height:60px; font-size:28px; color:$accent_color; background:#eee; text-align: center; line-height:60px; margin-right: 28px;}
		}
	}
	#contact_form {
		background-color: #f5f5f5;
		padding: 20px;
		border: 1px solid #ebebeb;
		@include box-shadow();
	}
}

@media (max-width: 1400px) {
	.r-contact-part{
		padding: 40px 0;
		.contact-head:after{
			font-size: 150px;
		}
		.contact-head h2{
			font-size: 32px;
			line-height: 40px;
		}
		.r-contact-address{
			margin-top: 30px;
			.head{
				font-size: 22px;
				margin-bottom: 25px;
			}
			.address-cnt{
				font-size: 18px;
    		margin-top: 10px;
				i{
					width: 50px;
					height: 50px;
					font-size: 22px;
					line-height: 50px;
					margin-right: 20px;
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.r-contact-part{
		padding: 80px 0 50px;
		.contact-head:after{
			font-size: 120px;
			left: -10px;
		}
		.contact-head h2{
			font-size: 28px;
			line-height: 36px;
		}
	}
}

@media (max-width: 1024px) {
	.r-contact-part{
		.r-contact-address{
			.head{
				font-size: 20px;
				margin-bottom: 20px;
			}
			.address-cnt{
				font-size: 16px;
				margin-top: 10px;
				i{
					width: 45px;
					height: 45px;
					font-size: 22px;
					line-height: 45px;
					margin-right: 20px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.r-contact-part{
		padding: 60px 0 30px;
		.r-contact-address{
			.head{
				font-size: 18px;
				margin-bottom: 15px;
			}
			.address-cnt{
				font-size: 14px;
				margin-top: 10px;
				i{
					width: 40px;
					height: 40px;
					font-size: 20px;
					line-height: 40px;
					margin-right: 15px;
				}
			}
			.r-contact-img{
				margin-bottom: 0px;
			}
		}
	}
}

@media (max-width: 480px) {
	.r-contact-part{
		padding: 60px 0 30px;
		.contact-head:after{
			font-size: 100px;
		}
		.contact-head h2{
			font-size: 26px;
			line-height: 34px;
		}
	}
}
