.r-header {
  position: relative;
  .r-header-strip {
    //position: absolute;
    //top: 0; //30px
    z-index: 9999;
    background: $accent_color;
    width: 100%;
    .r-logo {
      margin: 30px 0;
      padding-top: 10px;
    }

    .r-nav-section {
      nav {
        @extend .clearfix;

        > ul {
          list-style: none;
          overflow: hidden;
          float: left;
          margin-bottom:0;

          &:hover {
            overflow: visible;
          }

          > li {
            display: inline-block;
            margin-right: 25px;
            position: relative;

            > a {
              font-size: 16px;
              color: #333;
              font-weight: 500;
              text-decoration: none;
              display: inline-block;
              padding: 50px 0;
              -webkit-transition: all 0.2s linear;
              -moz-transition: all 0.2s linear;
              -ms-transition: all 0.2s linear;
              transition: all 0.2s linear;

              &:hover, &:active {
                color: #fff;
                font-weight: 500;
              }
            }

            &.r-has-child {
              &:hover {
                > ul {
                  opacity: 1;
                  transform: translateY(0);
                }
              }

              > ul {
                padding: 0;
                position: absolute;
                left: 0;
                top: 100%;
                min-width: 200px;
                background-color: $accent_color_black;
                list-style: none;
                opacity: 0;
                //transform: translateY(15px);
                //transition: all 0.5s ease;

                > li {
                  > a {
                    padding: 10px 15px;
                    display: block;
                    text-transform: uppercase;
                    color: set-notification-text-color($accent_color_black);
                    border-bottom: 1px solid lighten($accent_color_black, 10%);
                    font-size: 13px;
                    &:last-child {
                      border-bottom: none;
                    }
                    &::before {
                      content: ">>";
                      margin-right: 10px;
                    }

                    &:hover {
                      background-color: $accent_color;
                      color: set-notification-text-color($accent_color);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.r-header-fixed {
      position: fixed;
      width: 100%;
      top: 0;

      &.r-header-strip-01 {
        background: #2d2d2d;
        padding: 10px0;

        .r-submenu-icon {
          color: #fff;
        }
      }
    }
  }


  .r-header-inner-banner {
    background: url(../images/inner-header.jpg) no-repeat;
    background-size: 100% 600px;
    background-position: center center;
    //background-attachment: fixed;
    position: relative;
    top: 0;
    padding: 40px 0;
    //min-height: 600px;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(darken($accent_color_black, 30%), 0.65);
    }

    img {
      opacity: .35;
    }

    .r-header-in-over {
      //position: absolute;
      bottom: 150px;
      color: $breadcrumb-color;
      width: 100%;
      text-align: center;

      h1 {
        font-size: 50px;
        position: relative;
        z-index: 500;
        font-weight: 300;
        margin-bottom: 30px;

        b {
          font-weight: 700;
        }
      }
    }

    .r-breadcrum {
      ul {
        list-style: none;
        padding: 0;

        li {
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
          letter-spacing: 2px;
          position: relative;
          padding-right: 21px;

          a {
            color: #fff;
            font-weight: 500;

            &:hover {
              color: $accent_color;
            }
          }

          &::after {
            content: ">";
            font-size: 18px;
            display: block;
            padding: 0;
            position: absolute;
            right: 0;
            top: -3px;
            color: #fff;
          }

          &:last-child {
            padding-right: 0;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .r-header-action {
    a {
      text-align: center;
      display: table-cell;
      color: $color-white;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 32px 18px;
      border-left: 1px solid rgba($color-white, 0.7);
      border-right: 1px solid rgba($color-white, 0.7);
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      transition: all 0.2s linear;

      &:first-of-type {
        border-right: none;
      }

      &:hover {
        background: $accent_color;
      }
    }

    span {
      padding-top: 10px;
      display: block;
    }
  }

}


.r-header.r-header-strip-01 {
  .r-header-strip {
    background-color: transparent;
  }

  .r-header-action a {
    padding: 5px 18px;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .r-logo {
    margin: 10px 0;
  }

  .r-nav-section nav ul {
    padding-left: 0;

    li a {
      padding: 20px 0;
      color: #fff;

      &:hover {
        color: $accent_color;
      }
    }
  }

  .r-car-search {
    label {
      color: #fff;
    }

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
  }
}

.r-search-map-over.r-car-search {
  margin-top: -140px;

  .r-top-form-title {
    position: static;
    margin-bottom: 25px;

    h3 {
      font-weight: 300;

      &::after {
        top: 10px;
      }
    }
  }

  label {
    color: #fff;
  }
}

.r-search-map-over-in {
  background: url("../images/form-bg.jpg") no-repeat;
  background-size: cover;
  padding: 30px 30px 20px 30px;
}

.r-quote-sub.r-quote {
  padding-bottom: 180px;
}

.r-sub-banners {
  max-width: 1170px;
  margin: -105px auto auto;
  position: relative;

  .r-sub-banner-in {
    height: 195px;
    width: 50%;
    float: left;
    background-color: #333333;
    padding-top: 45px;
    padding-left: 240px;
    background-image: url("../images/sub-banner-01.png");
    background-repeat: no-repeat;
    background-position: left;

    span {
      display: block;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 15px;
      letter-spacing: 3px;
      color: #fff;
    }

    h4 {
      font-size: 30px;
      font-weight: 300;
      color: $accent_color;
      line-height: 40px;

      b {
        font-weight: 700;
      }
    }

    &.r-sub-accent {
      h4 {
        color: #212121;
      }

      background-color: $accent_color;
      color: #333;
      padding-left: 45px;
      background-image: url("../images/sub-banner-02.png");
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

.r-car-search {
  .r-top-form-title {
    position: absolute;
    top: -120px;

    span {
      font-size: 14px;
      color: #fff;
      font-weight: 400;
      display: block;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 32px;
      color: $accent_color;
      font-weight: 400;

      b {
        font-weight: 600;
      }

      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 3px;
        background-color: $accent_color;
        position: relative;
        top: 22px;
        left: 2px;
      }
    }
  }
}

.r-header.r-header-strip-03 {
  .r-header-action a {
    padding: 11px 18px;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .r-logo {
    margin: 10px 0;
    position: relative;
    //&:before {
    //  content: '';
    //  position: absolute;
    //  height: 50px;
    //  display: block;
    //  width: 100%;
    //  border-left: 250px solid $accent_color_black;
    //  border-bottom: 40px solid transparent;
    //  bottom:-50px;
    //  left:-20px;
    //  z-index: 1;
    //}
  }

  .r-nav-section nav ul {
    padding: 0;

    li {
      &.active, &:hover {
        a {
          color: $menu-active-color;
        }
      }
      a {
        padding: 34px 0;
        color: $white;
      }
    }
  }

  .r-car-search {
    label {
      color: $white;
    }

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
  }
}

.r-sub-head-text {
  position: relative;
  z-index: 9;
  padding-left: 10px;
}

.r-header.r-header-strip-02 {
  .r-header-strip {
    background: none;

    .r-logo {
      margin: 12px 0 6px 0;
    }
  }

  .container {
    background-color: #fff;
  }

  .r-header-action a {
    padding: 14px 18px;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .r-nav-section nav ul {
    padding-left: 0;

    li a {
      padding: 31px 0;
      color: #000;

      &:hover {
        color: $accent_color;
      }
    }
  }

  .r-header-action {
    i {
      color: #ccc;
      font-size: 27px;
    }

    span {
      color: #ccc;
    }

    a {
      &:hover {
        i, span {
          color: #fff;
        }
      }
    }
  }

  .r-car-search {
    label {
      color: #fff;
    }

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .r-header-fixed {
    background: #fff;
  }
}

.r-logo .fa {
  display: none;
}

.r-search-wrapper {
  position: fixed;
  bottom: 0;
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  width: 100%;
  background: rgba($black, 0.8);
  z-index: 999;
  left: 0;

  .r-search-inner {
    padding: 0 80px;

    form {
      position: relative;
    }

    .r-search-field {
      border: none;
      border-bottom: 2px solid rgba($white, 0.3);
      padding-left: 0;
      padding-right: 90px;
      padding-bottom: 0;
      padding-top: 0;
      color: $white;
      font-size: 68px;
      color: $white;
      letter-spacing: 1px;
      line-height: 120px;
      height: 122px;
      background-color: transparent;
      box-shadow: none;
      width: 100%;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .r-search-btn {
      position: absolute;
      top: 50%;
      right: 0;
      border: none;
      background: transparent;
      cursor: pointer;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      i {
        font-size: 42px;
        color: rgba($white, 0.5);
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        transition: all 0.2s linear;
      }

      &:hover {
        i {
          color: $accent_color;
        }
      }
    }

  }

  &.r-search-open {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (max-width: 1400px) {
  .r-header {
    .r-header-strip .r-nav-section {
      nav {
        > ul {
          padding-left: 0;

          > li {
            margin-right: 20px;

            a {
              font-size: 14px;
            }
          }
        }
      }
    }

    .r-header-inner-banner {
      //min-height: 500px;
      background-size: 100% 500px;

      .r-header-in-over {
        bottom: 120px;
      }

      .r-header-in-over h1 {
        font-size: 38px;
        margin-bottom: 15px;
      }

      .r-breadcrum ul li {
        font-size: 12px;
        right: 2px;

        &::after {
          right: 2px;
          font-size: 16px;
        }

        a {
          font-size: 12px;
        }
      }
    }
  }
  .r-search-wrapper {
    .r-search-inner {
      .r-search-field {
        font-size: 50px;
      }

      .r-search-btn i {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .r-header .r-header-inner-banner {
    background-size: cover;
  }
}

@media (max-width: 1100px) {
  .r-header {
    .r-header-strip .r-nav-section {
      nav {
        ul {
          padding-left: 0;

          li {
            margin-left: 3px;

            a {
              font-size: 12px;
            }
          }
        }
      }
    }

    .r-header-action a {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .r-header {
    .r-header-strip {
      top: 20px;
    }

    .r-header-strip .r-nav-section {
      nav {
        ul {
          padding-left: 0;
          padding-top: 15px;

          li {
            margin-right: 20px;
            margin-left: 3px;

            a {
              font-size: 12px;
              padding: 20px 0;
            }
          }
        }
      }
    }

    .r-header-strip .r-logo {
      margin: 14px 0;
    }

    .r-header-action {
      float: right;

      a {
        padding: 15px 10px;
      }
    }
  }
  .r-header .r-header-inner-banner {
    background-attachment: inherit;
  }
  .r-sub-banners .r-sub-banner-in {
    padding-top: 35px;
    padding-left: 100px;
  }
}

@media(max-width: 991px) {
  .r-header.r-header-strip-01 .r-car-search {
    position: relative;
    background: #000;
  }
  .r-header.r-header-strip-01 {
    .r-header-action a {
      font-size: 12px;
      padding: 0 15px;
    }
  }
  .r-header .r-header-action {
    img {
      height: 18px;
    }
  }
  .r-header .r-header-strip .r-nav-section nav ul li {
    margin-right: 5px;

    a {
      padding: 15px 0;
      font-size: 11px;
    }
  }
  .container {
    width: 100%;
  }
  .r-header.r-header-strip-03 .r-header-action a {
    padding: 11px 11px;
  }
  .r-header .r-header-strip .r-logo {
    margin: 16px 0 6px;
  }
  .r-header .r-header-action span {
    font-size: 13px;
  }

  .r-header .r-header-inner-banner {
    //min-height: 400px;

    .r-header-in-over h1 {
      font-size: 32px;
    }

    .r-breadcrum ul li a {
      font-size: 11px;
    }
  }
  .r-header .r-car-search .r-top-form-title {
    position: static;
    margin-bottom: 40px;
  }

}

@media (min-width: 768px) {
  .menu-icon {
    display: none;
  }
}

@media (max-width: 767px) {
  .r-header.r-header-strip-01 {
    .r-header-strip {
      background-color: #000;
    }
  }
  .menu-icon {
    position: absolute;
    top: 10px;
    right: 0;
    background: $accent_color;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: $accent_color_black;
    text-align: center;
    @include border-radius(5px);
    i {
      color: $accent_color_black;
    }
  }
  .r-nav-section {
    display: none;
  }
  .r-header-action {
    display: none;
  }
  .r-header .r-header-strip .r-logo {
    margin: 5px 0 0;
    max-width: 200px;
  }
  .r-header .r-header-strip .r-nav-section {
    position: absolute;
    background: $accent_color_black;
    width: 100%;
    left: 0;

    nav ul {
      width: 100%;
      float: none;

      li {
        float: none;
        display: block;
        margin: auto;

        a {
          padding: 10px 15px;
          color: #fff;
          display: block;
          border-bottom: 1px solid lighten($accent_color_black, 10%);
        }
      }
    }
  }
  .r-logo .r-menu-icon {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 18px;
    font-size: 26px;
  }

  .r-header.r-header-strip-02 .r-header-strip {
    .menu-icon {
      top: 20px;
      background: $accent_color_black;
      color: $accent_color;
      right: 15px;
    }
  }


  .r-header .r-header-strip .r-nav-section nav ul li {
    position: relative;
  }
  .r-submenu-icon {
    display: inline-block;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: $accent_color_black;
    color: #333333;
    font-size: 18px;
    line-height: 25px;
    position: absolute;
    right: 15px;
    z-index: 99;
    text-align: center;
    top: 5px;
  }

  .r-header .r-header-strip .r-nav-section nav > ul > li.r-has-child > ul {
    position: relative;
    display: none;
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .r-search-map-over.r-car-search {
    margin-top: -60px;

    .r-top-form-title h3 {
      font-size: 22px;
    }
  }
  .r-sub-banners .r-sub-banner-in {
    padding-left: 45px;
    width: 100%;
  }
  .r-header.r-header-strip-03 .r-car-search {
    position: static;
    background-color: #000;
  }
}

@media (max-width: 480px) {
  .r-header .r-header-strip {
    top: 10px;

    .r-logo {
      max-width: 180px;
    }
  }
  .menu-icon {
    right: 15px;
    top:15px;
  }

  .r-header.r-header-strip-02 .r-header-strip {
    position: relative;
    top: inherit;
  }

}
