.r-car-info-wrapper{
  padding: 100px 0;
  .r-car-info-header{
    margin-bottom: 50px;
    .r-car-rating{
      margin-bottom: 10px;
      i{
        color: $accent_color;
        font-size: 13px;
      }
      .r-rating-text{
        font-size: 12px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    .r-car-name{
      font-size: 32px;
      font-weight: 700;
      color: $accent_color_black;
      span{
        color: $accent_color;
        font-weight: 400;
        margin-left: 10px;
      }
    }
    .r-car-offer{
      float: right;
    }
    .r-offer-cost{
      display: inline-block;
      background: $accent_color;
      padding: 6px 15px;
      padding-left: 80px;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      position: relative;
      min-width: 260px;
      .r-currency{
        display: inline-block;
        padding: 0 20px;
        border-right: 1px solid #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        line-height: 62px;
      }
      small{
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .r-tax-info{
      display: block;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: $accent_color_black;
      text-align: right;
      margin-top: 10px;
    }

    .r-car-top-info{
      display: inline-block;
    }
  }
}

.r-car-product-carousel-wrapper{
  position: relative;
  .r-car-product-carousel-thumb{
    padding: 0;
    margin: 0;
    position: absolute;
    left: 30px;
    bottom: 30px;
    z-index: 99;
    li{
      display: inline-block;
      width: 60px;
      height: 60px;
      border: 3px solid #fff;
      & + li{
        margin-left: 5px;
      }
      img{
        height: 100%;
      }
      &.active{
        border-color: $accent_color;
        border-width: 4px;
      }
    }
  }
}

.r-car-whole-info{
  background: #f8f8f8;
  margin-bottom: 50px;
  .row{
    align-items: center;
  }
  .r-car-info-content{
    padding: 0 30px;
    padding-left: 15px;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: -50px;
      top: 50%;
      border-right: 20px solid #f8f8f8;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 999;
    }
    .r-reg-year{
      font-size: 15px;
      color: $accent_color_black;
      display: block;
      margin-bottom: 10px;
    }
    .r-product-name{
      font-size: 32px;
      font-weight: 700;
      color: $accent_color_black;
      margin-bottom: 15px;
      span{
        color: $accent_color;
        font-weight: 400;
      }
    }
    .r-product-description{
      font-size: 15px;
      color: #666666;
      line-height: 24px;
      margin-bottom: 30px;
    }
    table{
      td{
        background: #fff;
        font-size: 15px;
        color: #888888;
        border-color: #f8f8f8;
      }
    }
  }
}

.r-product-testimonial-wrapper{
  background: $accent_color;
  padding: 40px 35px 30px;
  margin-bottom: 40px;
  h4{
    font-size: 20px;
    font-weight: 600;
    color: $accent_color_black;
    margin-bottom: 15px;
  }
  .r-testimonial-box{
    color: $accent_color_black;
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
    line-height: 22px;
  }
  .owl-dots{
    margin-top: 15px;
    .owl-dot{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: rgba(#fff, 0.5);
      display: inline-block;
      & + .owl-dot{
        margin-left: 10px;
      }
      &.active{
        background: #fff;
      }
    }
  }
}

.r-product-discount{
  background: url(../images/discount-bg.jpg) no-repeat $accent_color_black;
  background-size: cover;
  padding: 55px 30px;
  min-height: 400px;
  .r-discount{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.22em;
    display: block;
    margin-bottom: 20px;
  }
  .r-discount-content{
    font-size: 25px;
    font-weight: 300;
    color: $accent_color;
    line-height: 33px;
    strong{
      font-weight: 700;
    }
  }
}

.r-site-default-accordion{
  margin-bottom: 50px;
  .r-accordion-header{
    cursor: pointer;
    border-bottom: 3px solid #eeeeee;
    padding-bottom: 12px;
    margin-bottom: 30px;
    .r-accordion-heading{
      font-size: 25px;
      font-weight: 600;
      color: $accent_color_black;
      line-height: 28px;
      display: inline-block;
      margin-bottom: 0px;
    }
    i{
      background: #f8f8f8;
      color: #cccccc;
      float: right;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
  .r-accordion-body{
    background: #fff;
    padding: 0 35px;
    .form-group{
      margin-bottom: 20px;
      label{
        color: $accent_color_black;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
    .input-group .form-control{
      border-left: 3px solid $accent_color;
    }
    .input-group-addon{
      background: #f8f8f8;
      border: none;
      color: #dddddd;
      font-size: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .r-fee-list{
      padding: 0px;
      margin: 0;
      li{
        list-style: none;
        border-bottom: 1px solid #eeeeee;
        padding-top: 8px;
        padding-bottom: 8px;
        display: block;
        label{
          display: block
        }
        .r-extra-fee{
          float: right;
          font-weight: 600;
        }
      }
    }
    textarea.form-control{
      height: 150px !important;
      resize: none;
      padding: 30px;
    }
  }
}

.r-site-checkbox{
  label{
    display: inline-block !important;
    color: $accent_color_black;
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-bottom: 0px !important;
    position: relative;
    padding-left: 35px;
    & + label{
      margin-left: 20px;
    }
    input{
      display: none;
      &:checked + .r-site-checkbox-icon{
        border-color: $accent_color;
        i{
          opacity: 1;
        }
        & + .r-site-checkbox-text{
          color: $accent_color_black;
        }
      }
    }
    .r-site-checkbox-text{
      color: #999999;
    }
    .r-site-checkbox-icon{
      width: 18px;
      height: 18px;
      border: 1px solid #e4e4e4;
      background: #f8f8f8;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 2px;
      i{
        color: $accent_color;
        font-size: 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        transition: all 0.2s linear;
      }
    }
  }
}

.r-payment-options{
  .r-site-checkbox{
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
    margin-bottom: 20px;
    img{
      float: right;
    }
  }

}

.r-booking-form-submission{
  .row{
    align-items: center;
    .r-submission-btn-wrapper{
      text-align: right;
      input{
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        & + input{
          margin-left: 10px;
        }
      }
    }
  }
}

.r-car-tabs{
  > .nav{
    margin-bottom: 50px;; border: none; border-bottom: 1px solid #eeeeee; text-align: center; display: block; position: relative; z-index: 99;
    li{
      border: none; display: inline-block;
      a{
        font-weight: 500; font-size: 16px; color: #ccc; padding-right: 0;padding-bottom: 15px; border: none; display: inline-block; padding-left: 0;
        &.active{color: #000; border-bottom: 3px solid $accent_color;}
        &:hover{color: #000;}
      }
      &::before{content:""; margin: 0 20px; width: 7px; height: 7px; border-radius: 50%; background-color: #ccc; display: inline-block;}
      &:first-child{
        &::before{display: none;}
      }
    }
  }
  .r-car-info-content{
    ul{
      padding-left: 0; list-style: none; @extend .clearfix; text-align: center;
      li{
        display: inline-block; color: #aaaaaa; background-color: #f4f4f4; padding: 2px 12px;
        &:hover{background-color: $accent_color;  color: #fff;}
        i{font-size: 12px; display: inline-block; margin-right: 5px;}
        span{font-size: 11px;}
      }
    }
  }
  .btn-full{max-width: 100%;}
}

.r-similar-product{
  padding-top: 80px;
  margin-top: 100px;
  border-top: 2px solid #e7e7e7;

  .heading{
    font-size: 28px;
    color: $accent_color_black;
    font-weight: 600;
    margin-bottom: 50px;
  }
}

.r-best-offer-list{
  .r-best-offer-single{
    .r-best-offer-content{
      background: #fff;
      padding-top: 10px;
    }
    .r-offer-img{
      background: #fff;
    }
    .r-offer-rewst-this{
      max-width: 100%;
    }
    ul{
      li{
        background: #fff;
      }
    }
  }
  .owl-dots{
    text-align: center;
    .owl-dot{
      width: 15px;
      height: 15px;
      background: #eeeeee;
      display: inline-block;
      border-radius: 50%;
      &.active{
        background: #cccccc;
      }
      & + .owl-dot{
        margin-left: 15px;
      }
    }
  }
}


@media (max-width: 1400px) {
  .r-car-info-wrapper{
    padding: 80px 0;
  }
  .r-car-info-wrapper .r-car-info-header .r-car-name,
  .r-car-whole-info .r-car-info-content .r-product-name{
    font-size: 28px;
  }
  .r-car-info-wrapper .r-car-info-header .r-offer-cost{
    font-size: 28px;
    .r-currency{
      line-height: 55px;
    }
    small{
      font-size: 12px;
    }
  }

  .r-car-info-wrapper .r-car-info-header{
    margin-bottom: 30px;
  }
  .r-car-whole-info .r-car-info-content .r-reg-year{
    font-size: 14px;
  }
  .r-car-whole-info .r-car-info-content .r-product-description{
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .r-car-whole-info .r-car-info-content table td{
    font-size: 13px;
  }

  .r-site-default-accordion .r-accordion-header .r-accordion-heading{
    font-size: 18px;
  }
  .r-site-default-accordion .r-accordion-header{
    border-bottom-width: 2px;
    margin-bottom: 20px;
  }

  .r-site-default-accordion .r-accordion-body .form-group label{
    font-size: 13px;
  }

  .r-site-default-accordion .r-accordion-body .form-control{
    height: 45px !important;
    font-size: 13px;
  }
  .r-site-default-accordion .r-accordion-body .input-group-addon{
    font-size: 18px;
  }
  .r-site-default-accordion .r-accordion-body .form-group{
    margin-bottom: 15px;
  }
  .r-site-default-accordion .r-accordion-body{
    padding: 0 20px;
  }
  .r-product-testimonial-wrapper h4{
    font-size: 18px;
  }
  .r-product-testimonial-wrapper .r-testimonial-box{
    font-size: 13px;
  }
  .r-product-testimonial-wrapper{
    padding: 35px 30px 30px;
    margin-bottom: 30px;
  }
  .r-product-testimonial-wrapper .owl-dots{
    margin-top: 10px;
    .owl-dot{
      width: 8px;
      height: 8px;
    }
  }
  .r-site-default-accordion{
    margin-bottom: 30px;
  }
  .r-site-checkbox label{
    font-size: 13px;
  }
  .r-payment-options .r-site-checkbox{
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .r-similar-product{
    margin-top: 80px;
    padding-top: 50px;
  }
  .r-similar-product .heading{
    font-size: 24px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1200px) {
  .r-car-info-wrapper{
    padding: 50px 0;
  }
}

@media (max-width: 1024px) {
  .r-car-whole-info .r-car-info-content .r-reg-year{
    margin-bottom: 5px;
  }
  .r-car-info-wrapper .r-car-info-header .r-car-name{
    font-size: 24px;
  }
   .r-car-whole-info .r-car-info-content .r-product-name{
     font-size: 20px;
     margin-bottom: 10px;
   }
   .r-car-info-wrapper .r-car-info-header .r-offer-cost{
     font-size: 24px;
     padding-left: 70px;
     small{
       font-size: 11px;
     }
   }
   .r-car-info-wrapper .r-car-info-header .r-offer-cost .r-currency{
     line-height: 50px;
     padding: 0 18px;
   }

   .r-car-whole-info .r-car-info-content .r-product-description{
     font-size: 13px;
     line-height: 20px;
     margin-bottom: 15px;
   }
  .r-car-whole-info .r-car-info-content table td{
    padding: 10px;
    font-size: 12px;
  }
  .r-car-whole-info .r-car-info-content{
    padding-left: 0px;
  }
  .r-car-whole-info{
    margin-bottom: 30px;
  }

  .r-booking-form-submission .row .r-submission-btn-wrapper input{
    font-size: 13px;
  }
  .r-booking-form-submission .r-site-checkbox{
    margin-bottom: 20px;
  }

  .r-booking-form-submission .row .r-submission-btn-wrapper{
    text-align: left;
  }
}

@media (max-width: 991px) {
  .r-car-product-carousel{
    .owl-item{
      img{
        width: 100%;
      }
    }
  }
  .r-car-whole-info .r-car-info-content{
    padding: 15px 25px;
    &::before{
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .r-car-info-wrapper{
    padding: 30px 0 20px;
  }
  .r-car-info-wrapper .r-car-info-header .r-car-offer{
    float: none;
  }
  .r-car-info-wrapper .r-car-info-header .r-car-name{
    margin-bottom: 15px;
    font-size: 22px;
  }
  .r-car-info-wrapper .r-car-info-header .r-car-rating{
    margin-bottom: 5px;
  }
  .r-car-info-wrapper .r-car-info-header .r-car-rating i{
    font-size: 12px;
  }
  .r-car-info-wrapper .r-car-info-header .r-tax-info{
    text-align: left;
    font-size: 11px;
  }
  .r-car-info-wrapper .r-car-info-header{
    margin-bottom: 20px;
  }

  .r-car-product-carousel-wrapper .r-car-product-carousel-thumb li{
    width: 50px;
    height: 50px;
    border-width: 2px;
    &.active{
      border-width: 2px;
    }
  }
  .r-car-product-carousel-wrapper .r-car-product-carousel-thumb{
    bottom: 20px;
  }
  .r-product-discount{
    background-size: auto 100%;
  }

  .r-product-testimonial-wrapper{
    padding: 30px 20px;
    margin-bottom: 20px;
  }
  .r-product-testimonial-wrapper .owl-dots .owl-dot + .owl-dot{
    margin-left: 5px;
  }
  .r-car-whole-info{
    margin-bottom: 20px;
  }
  .r-product-discount{
    margin-bottom: 20px;
  }
  .r-site-default-accordion .r-accordion-body{
    padding: 0 10px;
  }

  .r-similar-product{
    padding-top: 30px;
    margin-top: 50px;
  }

  .r-similar-product .heading{
    font-size: 20px;
    margin-bottom: 30px;
  }

  .r-similar-product .r-best-offer-list .owl-dots .owl-dot{
    width: 10px;
    height: 10px;
    & + .owl-dot{
      margin-left: 8px;
    }
  }

  .r-booking-form-submission .row .r-submission-btn-wrapper input{
    font-size: 12px;
  }

}

@media (max-width: 480px) {
  .r-car-product-carousel-wrapper{
    padding-bottom: 70px;
    .r-car-product-carousel-thumb{
      bottom: 0px;
    }
  }
  .r-product-testimonial-wrapper h4{
    font-size: 16px;
    margin-bottom: 10px;
  }
  .r-site-default-accordion .r-accordion-header .r-accordion-heading{
    font-size: 16px;
  }
  .r-site-default-accordion .r-accordion-body .form-control{
    height: 40px !important;
    font-size: 12px;
  }
  .r-site-default-accordion .r-accordion-body textarea.form-control{
    padding: 20px;
  }
  .r-site-checkbox label{
    font-size: 12px;
  }
  .r-site-default-accordion .r-accordion-body .form-group label{
    font-size: 12px;
  }
  .r-site-checkbox label .r-site-checkbox-icon{
    width: 15px;
    height: 15px;
  }
  .r-site-checkbox label .r-site-checkbox-icon i{
    font-size: 12px;
  }
  .r-payment-options .r-site-checkbox img{
    max-width: 120px;
  }
}



//
