$accent_color_01: $accent_color_black;
.r-video-msg-part{
	.r-video-right{
		border-left: solid 3px $accent_color_01;

	}
}
.r-car-search .r-top-form-title h3{color: $accent_color_01;}
.r-car-option-tab li.nav-item a.nav-link.active,.r-car-option-tab li.nav-item a.nav-link:hover{background-color: $accent_color_01; border-color: $accent_color_01;}
.r-faq-option-tab li.nav-item a.nav-link.active i,.r-faq-option-tab li.nav-item a.nav-link:hover i{color: $accent_color_01;}
.r-header .r-header-strip{background: $accent_color_01;}
.r-form-strip::before{background: $accent_color_01;}
.r-car-tabs > .nav li a.active{border-bottom-color: $accent_color_01;}
.r-slider-serach.r-slider-serach-down > form .form-title h2::after{background-color: $accent_color_01;}
.r-sub-banners .r-sub-banner-in h4{color: $accent_color_01;}
.r-to-top:hover {border-color: $accent_color_01; color: $accent_color_01;}
.r-car-search .r-top-form-title h3::after{background-color: $accent_color_01;}
.btn-full, .r-best-offer-list .r-best-offer-single .r-offer-rewst-this, .iw-content .r-best-offer-single .r-offer-rewst-this{
	background: $accent_color_01;
}
.r-sub-banners .r-sub-banner-in.r-sub-accent{background-color: $accent_color_01;}
.iw-content .r-best-offer-single .r-offer-img .r-offer-img-over i{background: $accent_color_01;}
.r-footer .r-footer-block .r-footer-phone span{color: $accent_color_01;}
.r-insurance-part{
	.r-insurance-box{
		&:hover{
			background:$accent_color_01;
		}
	}
}
.r-get-in-touch{
	.r-get-header{
		h2{color:$accent_color_01;}
	}
	.r-get-form{
		.text-center{
			button{
				background:$accent_color_01;
			}
		}
	}
}
.r-counter-section{
    &.r-counter-with-bg{
    	background-color: $accent_color_01;
    	div[class*="col-"]{border-right-color: $accent_color_01;}
    }
}
.r-about-info{
	.r-about-info-content{
		ul{
			li{
				i{color: $accent_color_01;}
			}
		}
	}
}
.r-who-royal{
	.r-about-info-content{
		.r-sec-head {
			h2{color: $accent_color_01;}
		}
		ul{
			li{ i{color: $accent_color_01; }}
		}
	}
}
.r-newsletter-suscribe{
	.r-newsletter-form{
		i{color: $accent_color_01;}
	}
}
.r-best-vehicles{
	.r-best-vehicle-list{
		.r-best-vehicle-single{
			.r-best-vehicle-content{
				.r-modal-name{
					&:hover{color: $accent_color_01;}
					span{color: $accent_color_01;}
				}
				.r-average{background-color: $accent_color_01;}
			}
		}
	}
	.r-best-leftbar{
		.r-best-vehicle-types{
		    background-color: $accent_color_01;
			li{
				span{
					border-bottom: 1px solid darken($accent_color_01, 10%);
					&::before{background-color: $accent_color_01;}
				}
				&::after{background-color: $accent_color_01;}
			}
 		}
	}
}
.r-best-offer-list{
	.r-best-offer-single{
		.r-offer-img{
			.r-offer-img-over{
				i{ background-color: $accent_color_01;}
			}
		}
		.r-best-offer-content{
			> a{
			    color: $accent_color_01;
				&:hover{color: $accent_color_01; b{color: $accent_color_01;}}
			}
		}
	}
}
.r-car-info-wrapper{
  .r-car-info-header{
    .r-car-rating{
      i{
        color: $accent_color_01;
      }
    }
    .r-car-name{
      span{
        color: $accent_color_01;
      }
    }
    .r-offer-cost{
      background: $accent_color_01;
    }

  }
}

.r-car-product-carousel-wrapper{
  .r-car-product-carousel-thumb{
    li{
      &.active{
        border-color: $accent_color_01;
      }
    }
  }
}

.r-car-whole-info{
  .r-car-info-content{
    .r-product-name{
      span{
        color: $accent_color_01;
      }
    }
  }
}

.r-product-testimonial-wrapper{
  background: $accent_color_01;
}

.r-product-discount{
  .r-discount-content{
    color: $accent_color_01;
  }
}

.r-site-default-accordion{
  .r-accordion-body{
    .input-group .form-control{
      border-left-color:$accent_color_01;
    }
  }
}

.r-site-checkbox{
  label{
    input{
      &:checked + .r-site-checkbox-icon{
        border-color: $accent_color_01;
      }
    }
    .r-site-checkbox-icon{
      i{
        color: $accent_color_01;
      }
    }
  }
}

.r-car-filter-area{
  .r-change-view{
    li{
      a{
        color: $accent_color_01;
      }
    }
  }
}
.r-best-vehicles .r-best-leftbar .r-best-vehicle-types.r-best-vehicle-category{
  li{
      i{
        color: $accent_color_01;
      }
  }
}
.r-hide-car-list{
  &:hover{background: $accent_color_01;}
}

.btn-primary{
    background: $accent_color_01;
}

.r-sec-head{
    &.r-sec-head-line{
        h2{
            &::after{background-color: $accent_color_01;}
        }
    }
}
.r-contact-part{
	.r-contact-address{
		.address-cnt{
			i{color:$accent_color_01;}
		}
	}

}
.r-driver-blog{
	.r-drivers{
		.r-drivers-detail{
			.name {border-left:solid 3px $accent_color_01;}
			.icon{
                a{
                    &:hover{
                        color: $accent_color_01;
                    }
                }
			}
		}
	}
}
.r-driver-blog-dark{
    .r-sec-head{
        h2{color: $accent_color_01;}
    }
}
.r-faq-header{
    h2{
        &::before{
            background: $accent_color_01;
        }
    }
}
.r-accordion{
  .r-accordion-body{
    h3{
      color: $accent_color_01;
    }
  }
}

.r-footer {
		.r-footer-block{
			.r-newsletter{
				button{background:$accent_color_01; }
			}
			.r-footer-nav{
                ul{
                    li{

                        &::before{
                            color: $accent_color_01;
                        }
                        a{
                            &:hover{
                                color: $accent_color_01;
                            }
                        }
                    }
                }
			}
            .r-footer-map {
                a{
                    background: $accent_color_01;
                }
            }
		}

    .r-footer-strip{
        ul{
            li{
                a{
                    &:hover{
                        color: $accent_color_01;
                    }
                }

            }
        }
    }
}

.r-footer-dark {
	.r-footer-block{
	 .r-footer-phone{
			h5{
				color: $accent_color_01;
			}
		}
	}

	.r-location-map{
		a{
			i{
				color: $accent_color_01;
			}
			&:hover{
				color: $accent_color_01;
			}
		}
	}
	.r-footer-strip{
		.r-copy-right-text{
			strong{
				color: $accent_color_01;
			}
		}
	}
}

.r-scroll-up-icon{
	background: $accent_color_01;
}

.r-gallery-part{
	ul{
		li{
			.gallery-hover{background:$accent_color_01;}
            .gallery-text{
					.icon-gallery{
						i{ color:$accent_color_01;}
					}
				}
		}
	}
}
.r-header{
	.r-header-strip{
		.r-nav-section {
			nav{
				> ul{
					> li{
						&.r-has-child{
							> ul{
								> li{
									>a{
										&:hover{background-color: $accent_color;}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.r-header-inner-banner{
		.r-breadcrum {
			ul{
				li{
					a{
                        &:hover{
                            color: $accent_color_01;
                        }
					}
				}
			}
		}
	}
}


.r-header.r-header-strip-01{
	.r-nav-section nav ul {
		li a{&:hover{color: $accent_color_01;}}
	}
}
.r-header.r-header-strip-02{
	.r-nav-section nav ul {
		li a{&:hover{color: $accent_color_01;}}
	}
}

.r-latest-news{
	.r-sec-head{
		h2{color: $accent_color_01;}
	}
}
.r-latest-news-content{
	h4{
		border-left: 2px solid $accent_color_01;
 		a{color: $accent_color_01;}
	}
	.r-read-more{ &:hover{color: $accent_color_01;}}
}
.r-follow-us{
	span{a{color: $accent_color_01;}}
}

.r-latest-news-light{
	.r-latest-news-content {
		.r-date{color: $accent_color_01;}
		h4{
			a{ &:hover{color: $accent_color_01;}}
		}
		.r-read-more{ &:hover{color: $accent_color_01;}}
	}
}

.r-login-register{
	.r-login-register-in{
		.r-auth-or{
			 background-color: $accent_color_01;
			span{background: $accent_color_01;}
		}
	}
}

.iw-content{
  .r-best-offer-single{
    .r-best-offer-content{
      > a{
        color: $accent_color_01;
        &:hover{color: $accent_color_01; b{color: $accent_color_01;}}
      }
    }
  }
}
.r-slider-serach.form-search .form-title h2 {
  color: $accent_color_01;
}
.r-slider-serach{
  > form{
    .row{
      .form-group{
          input{&:focus{border-color: $accent_color_01;}}
      }
    }
  }
}
.r-carlist-search{
  .r-search-full{
    .fa{background-color: $accent_color_01;}
  }
}
.r-advantages-part{
	.r-advantage-main-part{
		.r-advantages-box{
			.icon{color:$accent_color_01;}
			&:hover{
				background:$accent_color_01;
			}
		}
	}
	.r-what-offers{
		.r-offers-box{
			.icon{color:$accent_color_01;}
		}
	}
}

.r-advantages-part{
	.r-advantage-main-part{
		.r-advantages-box{
			.icon{color:$accent_color_01;}
			&:hover{
				background:$accent_color_01;
			}
		}
	}
	.r-what-offers{
		.r-offers-box{
			.icon{color:$accent_color_01; }
		}
	}
}

.r-new-member{
	.r-sec-head{
		h2{color: $accent_color_01;}
	}
	.r-discounted-car{
		.r-discount-single{
			.r-discount-content .r-starts{
				li{
					i{color: $accent_color_01;}
				}
			}
			h4{
				a{&:hover{color: $accent_color_01;}}
			}
			ul.r-car-point{
				li{
					&:hover{
						background-color: $accent_color_01;
					}
				}
			}
		}
	}
}
.r-slider-item{
  .r-slider-top-content{
    h1{
      span{
        color: $accent_color_01;
      }
    }
    .btn-outlined{
      &:hover{
        background: $accent_color_01;
        border-color: $accent_color_01;
      }
    }
  }
}

.r-slider{
	.owl-dots{
    .owl-dot{
      &.active{
        border-bottom: 2px solid $accent_color_01;
      }
    }
  }
}
.r-header .r-header-action a:hover {
    background: $accent_color_01;
}
.iw-content .r-best-offer-single ul li:hover{background-color: $accent_color_01;}
.r-best-offer-list .r-best-offer-single ul li:hover{background-color: $accent_color_01;}

.r-submenu-icon {
	i {
		color: orange;
	}
}
